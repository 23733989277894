import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <section className='section'>
      <div className='container'>
        <div className='content'>
          <h1>About Tristan Farmer</h1>
          <p>Welcome to the blog! page!</p>
        </div>
      </div>
    </section>

  </Layout>
)

export default SecondPage
